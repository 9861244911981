import { IResponse } from "@/models/IClient";
import { IClientUser } from "@/models/IClientUser";
import { IGroup, IGroupList } from "@/models/IGroup";
import { IPageResponse, Result } from "@/models/IPageWiseResponse";
import axios from "axios";
import auhtHeader from "./auth-header";
import auhtHeaderForUpload from "./auth-header-upload";
import getBaseURL from "./Base";

let base = getBaseURL();

const API_URL = base.baseURL;
const userId = base.userId;
const clientId = base.clientId;


class ClientUserService {
  clientUser!: IClientUser;
  lstGroup: IClientUser[] = [];
  responsedata: any;

  saveClientUser(model: IClientUser): Promise<IResponse> {

    model.userId = userId
    model.clientId = clientId

    this.responsedata = axios
      .post<IResponse>(API_URL + "ClientUser/saveclientuser", model, {
        headers: auhtHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    return this.responsedata;
  }

  uploadClientUser(model: FormData): Promise<IResponse> {

    this.responsedata = axios
      .post<IResponse>(API_URL + "ClientUser/uploadBulkuser", model, {
        headers: auhtHeaderForUpload(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    return this.responsedata;
  }

  getListofClientUser(
    branchId: number,
    pageIndex: number,
    search: string = ""
  ): Result {
    var URL =
      API_URL +
      "ClientUser/getclientuser/" +
      clientId +
      "/" +
      branchId +
      "/" +
      pageIndex;

    if (search.length > 0) {
      URL =
        API_URL +
        "ClientUser/getclientuser/" +
        clientId +
        "/" +
        branchId +
        "/" +
        pageIndex +
        "?search=" +
        search;
    }

    this.responsedata = axios
      .get<IPageResponse>(URL, { headers: auhtHeader() })
      .then((response) => {
        const results = response.data.result;

        return results;
      })
      .catch((error) => {
        console.log(error);
      });

    return this.responsedata;
  }

  getClientUserByUserId(
    userId: number
  ): Promise<IClientUser> {
    this.responsedata = axios
      .get<IResponse>(
        API_URL +
          "ClientUser/getclientuserbyuserid?userId=" +
          userId +
          "&clientId=" + clientId,
        { headers: auhtHeader() }
      )
      .then((response) => {
        return response.data.result;
      })
      .catch((error) => {
        console.log(error);
      });

    return this.responsedata;
  }

  getClientUserByBranch(
    branchId: number
  ): Promise<IClientUser[]> {
    this.responsedata = axios
      .get<IResponse>(
        API_URL +
          "ClientUser/getclientuserbyclientid?clientId=" +
          clientId +
          "&branchId=" + branchId,
        { headers: auhtHeader() }
      )
      .then((response) => {
        return response.data.result;
      })
      .catch((error) => {
        console.log(error);
      });

    return this.responsedata;
  }
}

export default new ClientUserService();
